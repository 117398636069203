import React from "react";
import Place from "./Place";
import vic from "../images/vic.jpg";
import "../App.css";
import "./Animation.css";
import Time from "./Time";
import license from "../images/1.jpg"
import license2 from "../images/2.jpg"
export default function Home() {
  return (
    <>
      <div className="home fade-in-right-header">
        <img
          src={vic}
          alt="蔡維克新手道路駕駛訓練, 車距感加強訓練, 油門煞車控制反應訓練, 標線燈號查看反應特別訓練"
        />
      </div>
      <div className="slider_bottom"></div> 
      <Time/>
      <Place />
      <div className="license">
      <h1>教練證照</h1>
      <img src={license} alt="執照照片"/>
      <img src={license2} alt="執照照片"/>
      </div>
      
    </>
  );
}
