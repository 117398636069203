import React from "react";

export default function Footer() {
  return (
    <div className="footer">
      © Copyright by
      <span>
        {" "}
        <a className="footer__link" href="https://www.leighleelin.com">
          Leigh Yang
        </a>
      </span>
      and
      <a
        className="footer__link"
        href="https://www.linkedin.com/in/hellohayeah/"
      >
        Jerry Tsai
      </a>
      .
      All Right Reserved.
    </div>
  );
}
