import React from "react";
import logo from "../images/Logo.png";
import "../App.css";
import "./Navbar.css";
import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <div className="navbar">
      <img className="logo" src={logo} alt="Vic" />
      <input id="menu-toggle" type="checkbox" />
      <label class="menu-button-container" for="menu-toggle">
        <div class="menu-button"></div>
      </label>
      <ul class="menu">
        <li>
          <Link className="nav__link" to="/">
            首頁
          </Link>
        </li>
        <li>
          <Link className="nav__link" to="/class">
            課程介紹
          </Link>
        </li>
        <li>
          <Link className="nav__link" to="/video">
            實際教學
          </Link>
        </li>
        <li>
          <Link className="nav__link" to="/information">
            課程購買須知
          </Link>
        </li>
        <li>
          <Link className="nav__link" to="/contact">
            預約課程
          </Link>
        </li>
      </ul>
    </div>
  );
}
