import React from "react";

export default function Class() {
  return (
    <div className="class fade-in-right-header">
      <div className="class__header">
        <h2>
          您是否有駕照但不敢開車呢？ 您是否面臨駕駛焦慮和開車心理壓力的困擾呢？
        </h2>
      </div>
      <div className="class__body">
        <p>
          許多人考到駕照後，雖然擁有駕駛車輛的資格，卻因缺乏實務上路經驗導致距離真正具備「安全」駕駛技能仍有相當大的距離，從而誕生類似於專屬課後輔導的「道路駕駛課」，使新手能夠在專業教練一對一的協助下，透過沒有限制時數的練習，幫助各種程度的新手學習如何應對各種道路情況和危險。
        </p>
        <p>
          而道路駕駛課與自家家人陪練最大的不同之處，就在於道路駕駛課能夠根據學生的需求，在任何地方尋找到合適的地點進行練習，甚至也能延伸至學生的生活範圍，無論是通勤路線、自家車庫或是想去的地方都可以，彈性極大；駕駛者能夠在教練一對一的指導下，循序漸進且沒有壓力的具備獨立上路的能力與自信。
        </p>
        <p>
          通過建立信心和技能，您將能夠從駕駛中獲得更多的樂趣，並且更加輕鬆地探索新的地方和目的地。不用再依賴他人也能夠自主地計劃和執行駕駛行程，無論是城市道路到高速公路，都能夠自如應對，並且自信和冷靜地應對不同的交通情況，從而確保駕駛安全。
        </p>
        <div className="class__h1">
          <h1>課程內容</h1>
        </div>

        <div className="class__cards fade-in-left">
          <div className="class__card">
            <div className="class__lavel">
              <h3>基礎理論知識</h3>
            </div>
            <div className="class__content">
              認識道路交通規則、標誌和標線，建立基本的交通安全意識。
            </div>
          </div>
          <div className="class__card">
            <div className="class__lavel">
              <h3>車輛操作技巧</h3>
            </div>
            <div className="class__content">
              學習車輛控制、轉彎、停車等基本操作技能，建立對車輛的掌控能力。
            </div>
          </div>
          <div className="class__card">
            <div className="class__lavel">
              <h3>交通環境模擬</h3>
            </div>
            <div className="class__content">
              進行各種交通環境模擬練習，包括城市道路、山路、高速公路等，讓您熟悉不同場景下的駕駛技能。
            </div>
          </div>
          <div className="class__card">
            <div className="class__lavel">
              <h3>實際道路駕駛</h3>
            </div>
            <div className="class__content">
              在教練的指導下，進行實際道路駕駛，學習應對真實交通情況的技巧。
            </div>
          </div>
        </div>
        <div className="class__get">
          <div className="class__h1">
            <h1>實際道路駕駛</h1>
          </div>
          而在一對一專屬道路駕駛課程中，您會擁有：
          <ol>
            <li>
              專業指導：由經驗豐富的專業駕駛教練主導，確保您得到最優質的指導和教學。
            </li>
            <li>
              個人化計劃：每位學員都有專屬的學習計劃，根據您的駕駛經驗和需求進行調整，確保最佳的學習效果。
            </li>
            <li>
              實際駕駛練習：我重視實際操作，課程中將有充足的駕駛練習時間，讓您能夠真實感受道路狀況並提升實際駕駛技能。
            </li>
            <li>
              信心建立：通過心理建設和放鬆技巧，幫助您建立駕駛時的自信心，克服焦慮情緒。
              我具備交通部合格汽車教練證書，多年來輔導過數千位學員安全上路，能準確地掌握您駕駛技巧不足之處加強練習，並且致力於提供一個安全、專業、歡樂的學習環境。無論是想增進駕駛技巧、提升自信心，或是重新建立開車的信心，我都將全力協助您達成目標。
            </li>
          </ol>
        </div>
      </div>

      {/* <div className="class__cards fade-in-left">
        <div className="class__card">
          <div className="class__lavel">
            <h3>初階</h3>
          </div>
          <div className="class__content">
            指定地點練習、煞車控制、油門控制、左右轉彎、回轉、左右後視鏡車距反應。
          </div>
        </div>
        <div className="class__card">
          <div className="class__lavel">
            <h3>進階</h3>
          </div>
          <div className="class__content">
            市區道路駕駛、變換車道、路邊停車、倒車入庫。
          </div>
        </div>
        <div className="class__card">
          <div className="class__lavel">
            <h3>高階</h3>
          </div>
          <div className="class__content">
            高速公路及快速道路駕駛、濱海公路、山區彎道特訓。
          </div>
        </div>
      </div> */}
    </div>
  );
}
