import React from "react";
import info from "../images/Info_fill.png";
import useGoogleSheets from "use-google-sheets";

export default function Time() {
  const sheetsOptions = [{ id: "上課時段" }];
  const { data, loading } = useGoogleSheets({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID,
    sheetsOptions,
  });

  if (loading) return <p>Loading...</p>;

  const [newData] = data;

  const tableTittle = newData.data.slice(0, 3).map((i, index) => {
    return (
      <div
        key={index}
        className={`time__table_hd table__border ${
          index == 1 ? "table__second" : ""
        }`}
      >
        {i.timetitle}
      </div>
    );
  });
  const weekdayFeeMorning = newData.data.slice(0, 3).map((i, index) => {
    return (
      <div key={index} className="table__border">
        {i.weekdayFeeMorning}
      </div>
    );
  });
  const weekdayFeeAfternoon = newData.data.slice(0, 3).map((i, index) => {
    return (
      <div key={index} className="table__border">
        {i.weekdayFeeAfternoon}
      </div>
    );
  });
  const weekdayFeeEvening = newData.data.slice(0, 3).map((i, index) => {
    return (
      <div key={index} className="table__border">
        {i.weekdayFeeEvening}
      </div>
    );
  });
  const weekendFeeMorning = newData.data.slice(0, 3).map((i, index) => {
    return (
      <div key={index} className="table__border">
        {i.weekendFeeMorning}
      </div>
    );
  });
  const weekendFeeAfternoon = newData.data.slice(0, 3).map((i, index) => {
    return (
      <div key={index} className="table__border">
        {i.weekendFeeAfternoon}
      </div>
    );
  });
  const weekendFeeEvening = newData.data.slice(0, 3).map((i, index) => {
    return (
      <div key={index} className="table__border">
        {i.weekendFeeEvening}
      </div>
    );
  });

  const tableNote = newData.data.map((i, index) => {
    return (
      <li key={index} className="fade-in-right">
        {i.note}
      </li>
    );
  });

  return (
    <div className="time">
      <h1 className="fade-in-right-header">上課時段</h1>

      <div className="time__table table__outborder fade-in-right">
        {tableTittle}
        <div className="table__border table__spanrow">平日班</div>
        {weekdayFeeMorning}
        {weekdayFeeAfternoon}
        {weekdayFeeEvening}
        <div className="table__border table__spanrow">假日班</div>
        {weekendFeeMorning}
        {weekendFeeAfternoon}
        {weekendFeeEvening}
      </div>
      <div>
        <div className="table__ps fade-in-right">備註:</div>
        <ol>{tableNote}</ol>
      </div>
      <div className="time__ps fade-in-right">
        <div>
          <img
            className="info "
            src={info}
            alt=" 蔡維克新手道路駕駛訓練, 車距感加強訓練, 油門煞車控制反應訓練, 標線燈號查看反應特別訓練"
          />
        </div>
        <div>{newData.data[0].addition}</div>
      </div>
    </div>
  );
}
