import React, { useEffect, useState } from "react";
import useGoogleSheets from "use-google-sheets";
import "./Animation.css";

const sheetsOptions = [{ id: "實際教學" }];

export default function Review() {
  const [w, setW] = useState("550");
  const [h, setH] = useState("315");
  useEffect(() => {
    if (window.screen.width <= 768) {
      setW("350");
      setH("200");
    } else {
      setW("550");
      setH("315");
    }
  }, window.screen.width);
  const { data, loading } = useGoogleSheets({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID,
    sheetsOptions,
  });

  if (loading) return <p>Loading...</p>;

  const [newData] = data;

  let youtube = newData.data.map((i, index) => {
    return (
      <div className="video_box">
        <h2 className="video_title fade-in-right">{i.標題}</h2>
        <iframe
          key={index}
          width={w}
          height={h}
          className="review_video"
          src={i.網址}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          allowFullScreen
        ></iframe>
      </div>
    );
  });
  return (
    <div className="videos">
      <h1 className="fade-in-right-header">實際教學</h1>
      <div>
        <a
          href="https://www.youtube.com/@tasi6600"
          className="social__link fade-in-right"
          target="_blank"
        >
          <i class="fa-brands fa-youtube icons"></i>
          蔡維克新手駕駛 安全訓練
        </a>
      </div>
      <div className="video">{youtube}</div>
    </div>
  );
}
