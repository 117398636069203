import React from "react";

import Time from "./Time";

import useGoogleSheets from "use-google-sheets";
import driving_school from "../images/driving-school.png";

export default function Imformation() {
  const sheetsOptions = [{ id: "其他注意事項" }];
  const { data, loading } = useGoogleSheets({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID,
    sheetsOptions,
  });
  if (loading) return <p>Loading...</p>;

  const [newData] = data;
  const info = newData.data.map((i, index) => {
    return <li key={index}>{i.其他注意事項}</li>;
  });

  return (
    <>
  
      <img
        className="img__driving_school rotate_pic"
        src={driving_school}
        alt="蔡維克新手道路駕駛訓練, 車距感加強訓練, 油門煞車控制反應訓練, 標線燈號查看反應特別訓練"
      />
      <div className="information">
        <h1 className="fade-in-right-header">其他注意事項</h1>
        <ul className="fade-in-left">{info}</ul>
        <div className="time__ps">
          ※以上內容如完成學費匯款後將視同同意內容約定，感謝您。
        </div>
      </div>
    </>
  );
}
