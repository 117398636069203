import React, { useState, useEffect } from "react";

export default function Place() {
  const data = {
    taipei:
      "1m18!1m12!1m3!1d3614.24393632741!2d121.53060507549375!3d25.05971997779976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a95ecf1e8495%3A0x355fd99512a8ac7b!2z6KGM5aSp5a6u!5e0!3m2!1szh-TW!2stw!4v1698310990925!",
    hsr: "1m18!1m12!1m3!1d3615.6435881660263!2d121.2125510754922!3d25.012223977830452!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346821087403b9bb%3A0x5c42c0d7e5175885!2z6Z2S5Z-U6auY6ZC156uZ!5e0!3m2!1szh-TW!2stw!4v1698311365455!",
    a7: "!1m18!1m12!1m3!1d3614.788638799794!2d121.38285907549316!3d25.041245977811734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a76e1574eccb%3A0xc5c8091f004bb64c!2z6auU6IKy5aSn5a24!5e0!3m2!1szh-TW!2stw!4v1698671598241",
    a8: "!1m18!1m12!1m3!1d3614.219220618132!2d121.36798532549378!3d25.06055792779921!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a73b458552e9%3A0xcedc6fc306bb19ea!2z5qGD5ZySQTjmqZ_loLTmjbfpgYs!5e0!3m2!1szh-TW!2stw!4v1698671736628",
    a9: "!1m18!1m12!1m3!1d3614.050927073795!2d121.35873342549392!3d25.066262977795507!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a72ef568b63f%3A0xfffbd615ca8622b!2zMjQ05paw5YyX5biC5p6X5Y-j5Y2A5paH5YyW5LiJ6Lev5LiA5q615qmf5aC05o236YGL5p6X5Y-j56uZ!5e0!3m2!1szh-TW!2stw!4v1698671797690",
    art: "!1m18!1m12!1m3!1d57848.43563705225!2d121.22202544863279!3d25.016175799999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34681fb0e37d3b2d%3A0xbaf1fa9b489e264d!2z5qGD5ZyS6Jed5paH5buj5aC0!5e0!3m2!1szh-TW!2stw!4v1698671905812",
    park: "!1m18!1m12!1m3!1d3615.956114858051!2d121.28637628234192!3d25.001607120692753!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34681f0961d89e8d%3A0xbb5d2a426e7c3600!2z6aKo56a-5YWs5ZyS!5e0!3m2!1szh-TW!2stw!4v1698671983033",
    train:
      "1m18!1m12!1m3!1d3616.3294213789995!2d121.31186507549147!3d24.988919977845633!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34681ee34594ac3f%3A0x8cac39b16aa24a7b!2z5qGD5ZyS!5e0!3m2!1szh-TW!2stw!4v1698934551029",
  };

  const [place, setPlace] = useState(data.hsr);
  const [w, setW] = useState("500");
  const [h, setH] = useState("450");
  useEffect(() => {
    if (window.screen.width <= 768) {
      setW("350");
      setH("200");
    } else {
      setW("500");
      setH("450");
    }
  }, window.screen.width);
  const changeLink = (name) => {
    Object.keys(data).forEach(function (mapData) {
      if (name === mapData) {
        setPlace(data[name]);
      }
    });
  };
  return (
    <div className="place">
      <h1>集合地點</h1>
      <div className="googlemap">
        <iframe
          src={
            `https://www.google.com/maps/embed?pb=` +
            place +
            `!5m2!1szh-TW!2stw`
          }
          width={w}
          height={h}
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div>
        <ul>
          <li>
            台北地區 —
            <button className="btn__map" onClick={() => changeLink("taipei")}>
              【行天宮站2號出口】
            </button>
          </li>
          <li>
            桃園地區 —
            <button className="btn__map" onClick={() => changeLink("hsr")}>
              【青埔高鐵站A11】
            </button>
            <button className="btn__map" onClick={() => changeLink("a8")}>
              【林口機捷 A8】
            </button>
            <button className="btn__map" onClick={() => changeLink("a7")}>
              【林口機捷 A7】
            </button>
            <button className="btn__map" onClick={() => changeLink("a9")}>
              【林口機捷 A9】
            </button>
            <button className="btn__map" onClick={() => changeLink("park")}>
              【風禾公園】
            </button>
            <button className="btn__map" onClick={() => changeLink("art")}>
              【藝文特區】
            </button>
            <button className="btn__map" onClick={() => changeLink("train")}>
              【桃園火車站】
            </button>
          </li>
        </ul>
        <div className="place_note">
          採一對一教學，初次上課會先了解您的開車狀況
          <br />
          教學時使用教練的車，配有安全副煞車
          <br />
          如欲使用自家車練習，請務必提前告知
        </div>
      </div>
    </div>
  );
}
