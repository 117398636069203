import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

export default function Contact() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_iwkiqhq",
        "template_ppbqmyo",
        form.current,
        "IJwCmDsNBn6X9Bd3j"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <div className="contact">
      <h1 className="fade-in-right-header">預約課程</h1>
      <div className="contact__flex fade-in-right">
        <form ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            className="form_contact"
            placeholder="名字"
            name="name"
          />
          <input
            type="tel"
            pattern="[0-9]{10}"
            className="form_contact"
            placeholder="電話"
            name="phone"
          />
          <textarea
            name="message"
            className="form_contact form_textarea"
            placeholder="留下訊息，預約時間或地點"
          />
          <input type="submit" value="提交" className="form_contact form_btn" />
        </form>
        <div className="contact__social fade-in-right">
          <a
            className="social__link"
            target="_blank"
            href="https://www.facebook.com/line0938941411"
          >
            <i className="fa-brands fa-facebook icons"></i>
            <div>蔡維克 新手道路駕駛訓練</div>
          </a>
          <div className="social__link">
            <i className="fa-solid fa-at icons"></i>
            <div>tasi6600@gmail.com</div>
          </div>
          <a
            href="http://lin.ee/ksBVvXi"
            target="_blank"
            className="social__link"
          >
            <i className="fa-brands fa-line icons"></i>
            <div>@vic13098</div>
          </a>
          <a
            href="https://www.youtube.com/@tasi6600"
            target="_blank"
            className="social__link"
          >
            <i class="fa-brands fa-youtube icons"></i>
            <div>蔡維克新手駕駛 安全訓練</div>
          </a>

          <a
            href="https://www.tiktok.com/@tasi6600"
            target="_blank"
            className="social__link"
          >
            <i class="fa-brands fa-tiktok icons"></i>
            <div>@tasi6600</div>
          </a>
        </div>
      </div>
    </div>
  );
}
